import { parseEnv } from './environment';
import { LOG_METHODS, LoggerParams, Options } from './types';

const levelsObj = {
  trace: 0,
  debug: 1,
  info: 2,
  warn: 3,
  error: 4,
  none: 5,
};

export type LogLevel = keyof typeof levelsObj;

class LoggerClass {
  private _level: number;
  color: Options['color'] = 'blue';
  name: Options['name'] = 'logger';
  private _log_level: LogLevel;
  private static instance: LoggerClass;

  constructor() {
    const defaultLogLevel =
      process.env['NODE_ENV'] === 'production' ? 'none' : 'debug';

    this._log_level = parseEnv() ?? defaultLogLevel;
    this._level = levelsObj[this._log_level];
    if (!LoggerClass.instance) {
      this.setInstance();
    }

    return LoggerClass.getInstance();
  }

  _log(level: LogLevel, params: LoggerParams, method?: LOG_METHODS) {
    if ((levelsObj[level] as number) >= this._level && method) {
      console[method](`%c${this.name}:`, `color: ${this.color}`, ...params);
    }
  }

  set level(level: LogLevel) {
    this._level = levelsObj[level];
  }

  log(...params: LoggerParams) {
    //FIXME: we should use the internal _log method , add a new log level "log" and spread params to account for multiple elements passed logger.log("text",value)

    // eslint-disable-next-line no-console
    console.log(params);
  }
  public static getInstance(): LoggerClass {
    return LoggerClass.instance;
  }

  setInstance() {
    LoggerClass.instance = this;
  }

  setConfig({ color, name }: Options) {
    this.color = color;
    this.name = name;
  }

  trace(...params: LoggerParams) {
    this._log('trace', params, LOG_METHODS.TRACE);
  }
  debug(...params: LoggerParams) {
    this._log('debug', params, LOG_METHODS.DEBUG);
  }
  info(...params: LoggerParams) {
    this._log('info', params, LOG_METHODS.INFO);
  }
  warn(...params: LoggerParams) {
    this._log('warn', params, LOG_METHODS.WARN);
  }
  error(...params: LoggerParams) {
    this._log('error', params, LOG_METHODS.ERROR);
  }
}
const logger = new LoggerClass();

export { logger };
