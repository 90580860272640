import { trackEvent } from '@checkout-ui/demo-shop/services';
import { Caption } from '@checkout-ui/design-system/Caption';
import { SvgIcon as Icon } from '@checkout-ui/design-system/LazyIcon';
import { List } from '@checkout-ui/design-system/List';
import { FormattedMessage } from '@checkout-ui/shared-context-locale';
import clsx from 'clsx';

import { ViewportType } from './context/types';
import { useViewportToggler } from './context/ViewPortContext';
import styles from './viewport-toggler.module.scss';

const viewportList: {
  type: ViewportType;
  name: React.ComponentProps<typeof Icon>['name'];
}[] = [
  {
    type: ViewportType.DESKTOP,
    name: 'desktop',
  },
  {
    type: ViewportType.TABLET,
    name: 'tablet',
  },
  {
    type: ViewportType.MOBILE,
    name: 'mobile',
  },
];

export function ViewportToggler() {
  const { handleViewportChange, viewport, toggleNav, isNavShown } =
    useViewportToggler();

  const navIcon = isNavShown ? 'close' : 'menu';

  const ViewPortList = () => (
    <List className={styles['viewport']} dataTest="viewport-toggler">
      {viewportList.map((viewportListItem) => {
        return (
          <List.Item
            key={viewportListItem.type}
            alignItems="center"
            justifyContent="center"
            className={clsx(
              styles['viewport__item'],
              styles[`'viewport__item--${viewportListItem.type}`],
              viewport === viewportListItem.type &&
                styles['viewport__item--active']
            )}
            dataTest={`viewport-${viewportListItem.type}`}
            onClick={() => {
              handleViewportChange(viewportListItem.type);
              trackEvent(`${viewportListItem.type} view selected`);
            }}
          >
            <Icon name={viewportListItem.name} size="md" />
            <Caption color="text--inherit">
              <FormattedMessage
                id={`demo-shop.viewport-toggler.${viewportListItem.type}`}
              />
            </Caption>
          </List.Item>
        );
      })}
    </List>
  );

  return (
    <div className={styles['container']}>
      <Icon
        name={navIcon}
        className={styles['menu']}
        onClick={toggleNav}
        size="md"
      />
      {isNavShown ? (
        <div className={styles['viewport__placeholder']} />
      ) : (
        <ViewPortList />
      )}
    </div>
  );
}

export default ViewportToggler;
